import { PAGES_WITH_REFERRER_PATH } from '~/constants/general';
import { SESSION_STORAGE } from '~/constants/storedPropertiesNames';
import { useAuthStore } from '~/stores/auth';
import type { PagesWithReferrerPath } from '~/types/general/globals';

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) {
    return;
  }

  const { $i18n } = useNuxtApp();
  const { getUserData } = useAuthStore();
  const parentPathIndex = Number(getUserData.locale !== $i18n.defaultLocale) + 1;
  const parentPathTo = to.path.split('/')[parentPathIndex] as keyof PagesWithReferrerPath;
  const parentPathFrom = from.path.split('/')[parentPathIndex] as keyof PagesWithReferrerPath;

  if (
    !Object.values(PAGES_WITH_REFERRER_PATH).includes(parentPathTo) &&
    Object.values(PAGES_WITH_REFERRER_PATH).includes(parentPathFrom)
  ) {
    sessionStorage.removeItem(SESSION_STORAGE.oldPath);
  }
});
